
          @use 'sass:math';
          $env: 'production';
          @import '/var/build/src/styles/utils/index';
        






































.ctas {
  border-top: 1px solid $c-light-grey;

  ::v-deep .cta-text__content {
    border: 0;

    &::before {
      content: none;
    }
  }
}
