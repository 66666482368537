
          @use 'sass:math';
          $env: 'production';
          @import '/var/build/src/styles/utils/index';
        

























































































































.personality-gallery {
  position: relative;
}

.personality-gallery__list {
  @extend %list-nostyle;

  @include mq(m) {
    display: flex;
    justify-content: space-between;
    align-items: flex-end;
  }
}

.personality-gallery__item {
  & + & {
    margin-top: $spacing * 2.25;
  }

  @include mq(m) {
    flex: 1;

    & + & {
      margin: 0 0 0 $spacing * 5;
    }

    &:nth-child(2) {
      position: relative;
      top: -5rem;
    }
  }
}

.personality-gallery__item__picture {
  width: 100%;

  @include mq(m) {
    img {
      transform: rotate(-5deg);
    }

    .personality-gallery__item:nth-child(2n) & {
      img {
        transform: rotate(5deg);
      }
    }
  }
}

.personality-gallery__item__picture__img {
  width: 100%;
  aspect-ratio: 3/2;
  border-radius: 1.5rem;
  box-shadow: 0 1.5rem 4.6rem rgba(0, 0, 0, 0.15);

  @include mq(m) {
    transform: rotate(-5deg);

    .personality-gallery__item:nth-child(2n) & {
      transform: rotate(5deg);
    }
  }
}

.personality-gallery__item__picture__caption {
  @extend %text-center;

  margin-top: $spacing;

  @include mq(m) {
    margin: $spacing * 2 0 0;
    font-size: 1.5rem;
    line-height: 2.5rem;
  }
}

[class*='personality-gallery__arrow--'] {
  position: absolute;
  top: -30%;
  left: 32%;

  &[class*='--right'] {
    top: -22%;
    left: 61%;
  }

  @include mq($until: m) {
    display: none;
  }
}
